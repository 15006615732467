import { css } from "emotion";
import PropTypes from "prop-types";
import React from "react";

import { FaTwitter } from 'react-icons/fa'
import { FaFacebook } from 'react-icons/fa'
import { FaEnvelope } from 'react-icons/fa'
import { FaLinkedin } from 'react-icons/fa'

import { ShareButtonCircle, ShareBlockStandard } from "react-custom-share";

const CustomReactShare = props => {
    const { url, title, excerpt } = props;

    const customStyles = css`
    border-radius: 50% 0 50% 0;
    margin: 0 10px;
    flex-basis: 60px;
    height: 60px;
    flex-grow: 0;
  `;

    const shareBlockProps = {
        url: url,
        button: ShareButtonCircle,
        buttons: [
            { network: "Twitter", icon: FaTwitter },
            { network: "Facebook", icon: FaFacebook },
            { network: "Email", icon: FaEnvelope },
            { network: "Linkedin", icon: FaLinkedin },
        ],
        text: title,
        longtext: excerpt,
        buttonCustomClassName: customStyles
    };

    return <ShareBlockStandard {...shareBlockProps} />;
};

CustomReactShare.propTypes = {
    url: PropTypes.string,
    title: PropTypes.string,
    excerpt: PropTypes.string,
    text: PropTypes.string
};

CustomReactShare.defaultProps = {
    url: "https://mywebsite.com/page-to-share/",
    title: "Default value of title",
    excerpt: "Default value of excerpt",
    text: '#article via @ailuj876'
};

export default CustomReactShare
